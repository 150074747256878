import React from "react";
import { createGlobalStyle } from "styled-components";
import Theme from "./src/Themes/theme";

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Julius Sans One', sans-serif;
    src: url("./fonts/JuliusSansOne-Regular.ttf") format('truetype');
    font-weight: normal;
  }
  @font-face {
    font-family: 'PT Sans', sans-serif;
    src: url("./fonts/PTSans-Regular.ttf") format('truetype');
    font-weight: normal;
  }
  @font-face {
    font-family: 'PT Sans', sans-serif;
    src: url("./fonts/PTSans-Bold.ttf") format('truetype');
    font-weight: normal;
  }
  @font-face {
    font-family: 'Verdana', Geneva, sans-serif; 
    src: url("./fonts/Verdana.ttf") format('truetype');
    font-weight: normal;
  }
  @font-face {
    font-family: 'Verdana', Geneva, sans-serif; 
    src: url("./fonts/Verdanab.ttf") format('truetype');
    font-weight: normal;
  }
  @font-face {
    font-family: 'fontello';
    src: url('./fonts/fontello.eot?31408486');
    src: url('./fonts/fontello.eot?31408486#iefix') format('embedded-opentype'),
        url('./fonts/fontello.woff?31408486') format('woff'),
        url('./fonts/fontello.ttf?31408486') format('truetype'),
        url('./fonts/fontello.svg?31408486#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  .screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;
  }

  html {
    font-size: 100%;
    scroll-behavior: smooth;

    @media only screen and (max-width: 1200px) {
        font-size: 90%;
    }
  }

  body {
    background-color: white;
    color: ${(props) => props.theme.colors.mainPurple};
    margin: 0;
    padding: 0;
    overflow-x: hidden;

    /* For the active mobile menu */
    &.active-mobile-menu {
      overflow: hidden;
    }
    /* For the active header */
    &.scroll-active header {
      position: fixed;
      background-color: white;
      top: 0;
      left: 0;
      right: 0;
      padding: 0;
      box-shadow: 0 0 13px rgba(0,0,0,0.3);
      z-index: 3;

      &:after {
        display: none;
      }

      .inner-wrapper {
        padding: 1.1rem 6rem 0.8rem;

        .logo-inner {
          transform: translateY(5px);
        }
        #header-menu li {
          a {
            font-size: 1rem;
          }

          &:last-child a {
            padding: 0.6rem 1rem;
          }
        }

        @media (max-width: 810px) {
          padding: 1.1rem 3rem 0.8rem;
        }
        @media (max-width: 440px) {
          padding: 1.1rem 1rem 0.8rem;
        }
      }
    }

    // For the separator element
    .separator-graphic {
      position: absolute;
      background-color: ${(props) => props.theme.colors.mainBG};
      bottom: -138px;
      width: 100%;
      transform: skewY(-9deg);
      height: 100%;
      z-index: -1;

      .element-wrap-1 {
        &:before {
          position: absolute;
          content: "";
          background-color: ${(props) => props.theme.colors.mainPurple};
          width: 450px;
          height: 40px;
          bottom: -40px;
          left: 0;
          opacity: 0.8;
        }
        &:after {
          content: "";
          position: absolute;
          background-color: #084ca1;
          width: 170px;
          height: 40px;
          bottom: -60px;
          left: 0;
          opacity: 0.8;
        }
      }
    }
  }

  // Max width on all homepage panels -> prevent broken styling
  section {
    position: relative;
    padding: 0 6rem;
    scroll-margin-top: 7rem;

    > .inner-wrapper {
      padding: 5rem 0;
      max-width: 1280px;
      margin: 0 auto;
    }
    @media only screen and (max-width: 810px) {
      padding: 0 3rem;

      > .inner-wrapper {
        padding: 4rem 0;
      }
    }
    @media only screen and (max-width: 440px) {
      padding: 0 1rem;

      > .inner-wrapper {
        padding: 3rem 0;
      }
    }
  }

  // Main body elements
  h1 {
    font-family: 'Julius Sans One', sans-serif;
    font-weight: 400;
    font-size: 2.8rem;
    line-height: 1;
  }
  h2 {
    font-family: 'Verdana', Geneva, sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.5;
  }
  h3, h4, h5, h6 {
    font-family: 'PT Sans', sans-serif;
    font-weight: 400;
    font-size: 2rem;
    line-height: 1.5;
  }
  h4 {
    font-size: 1.7rem;
    margin-bottom: 1rem;
  }
  h5 {
    font-size: 1.5rem;
  }
  h6 {
    font-size: 1.2rem;
  }
  p, a, li, label, input, textarea, div {
    font-family: 'PT Sans', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
  }
  i.custom-icon {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    font-size: 1.1rem;
  }

  a, button {
    cursor: pointer;
    text-decoration: none;
    color: ${(props) => props.theme.colors.mainBlue};
  }

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    list-style: none;
  }

  li a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.mainBlue};
  }
`;

export const wrapRootElement = ({ element }) => (
  <Theme>
    <GlobalStyles />
    {element}
  </Theme>
);
