import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  fonts: {
    heading: "Julius Sans One, sans-serif",
    subheading: "Verdana, Geneva, sans-serif",
    text: "PT Sans, sans-serif",
  },
  colors: {
    mainBlue: "#28384B",
    mainPurple: "#3F4F8A",
    white: "#FFFFFF",
    mainBG: "#F6F9FC",
    logoPurple: "#3F4F8A",
    logoPurpleLight: "#AB9ACF",
    logoOrange: "#C55200",
    logoYellow: "#FDC828",
    logoBlue: "#1A75BC",
    logoBlueLight: "#59C8EB",
    logoPink: "#C54886",
    logoPinkLight: "#D186D3",
    logoWhite: "#DEDEDE",
  },
};

const Theme = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

export default Theme;
